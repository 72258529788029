import { Button } from "antd";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Firebase } from "../../config/firebase";
import { Menu, CircleX } from 'lucide-react';
import "../../styles/home.scss";
import Footer from "./footer";

const HomeLayout = ({ children, authPage, isForCreatorsPage = false }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  async function onChange(user) {
    if (user) {
      setIsLoggedIn(true);
    }
  }

  const handleMenuClick = () => {
    setIsMenuOpen(open => !open);
  };

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);


  console.log('isMenuOpen-> ', isMenuOpen);
  return (
    <div
      style={{
        backgroundColor: "#FAF7EF",
      }}
    >
      <div className="header-wrapper">
        <header style={{ backgroundColor: "white" }}>
          <div className="header">
            <div className="nav-section">
              <Link to="/" className="logo">
                <img src="/output.png" width="30px" height="30px" alt="logo" />
                <h3>Output</h3>
              </Link>
              <div className={`nav-menu ${isMenuOpen ? 'nav-menu-open' : ''}`}>
                <ul>
                  {!isForCreatorsPage && (
                    <>
                      {!authPage && (
                        <li>
                          <Link
                            to="/pricing"
                            className="link"
                            style={{ fontSize: 16 }}
                          >
                            Pricing
                          </Link>
                        </li>
                      )}
                      {!authPage && (
                        <li>
                          <Link
                            to="/roadmap"
                            className="link"
                            style={{ fontSize: 16 }}
                          >
                            Roadmap
                          </Link>
                        </li>
                      )}
                    </>
                  )}

                  {isForCreatorsPage && (
                    <>
                      {!authPage && (
                        <li>
                          <Link
                            to="/pricing"
                            className="link"
                            style={{ fontSize: 16 }}
                          >
                            How It Works
                          </Link>
                        </li>
                      )}
                      {!authPage && (
                        <li>
                          <Link
                            to="/roadmap"
                            className="link"
                            style={{ fontSize: 16 }}
                          >
                            $Monetization
                          </Link>
                        </li>
                      )}
                    </>
                  )}

                  {!isLoggedIn && (
                    <li>
                      <Link
                        to={
                          isForCreatorsPage ? "/login?mode=creator" : "/login"
                        }
                        className="link"
                        style={{ fontSize: 16 }}
                      >
                        Login
                      </Link>
                    </li>
                  )}

                  {isForCreatorsPage && (
                    <>
                      <li>
                        <Link to="/signup?mode=creator">
                          <Button
                            type="primary"
                            width="100px"
                            size="large"
                            style={{
                              fontSize: 16,
                            }}
                          >
                            Start now
                          </Button>
                        </Link>
                      </li>
                    </>
                  )}

                  {!isForCreatorsPage && (
                    <>
                      {!isLoggedIn ? (
                        <li>
                          <Link to="/signup">
                            <Button
                              type="primary"
                              width="100px"
                              size="large"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              Try for free
                            </Button>
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link to="/dashboard">
                            <Button
                              type="primary"
                              width="100px"
                              size="large"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              Go to dashboard
                            </Button>
                          </Link>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <div onClick={handleMenuClick} className="mobile-burger">
                {isMenuOpen ? <CircleX /> : <Menu />}
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className={!isForCreatorsPage ? `container` : ""}>{children}</div>
      <Footer />
    </div>
  );
};

export default HomeLayout;
